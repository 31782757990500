<template>
  <v-dialog v-model="dialog" max-width="650px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" class="mr-2" text icon small>
        <v-icon color="white">
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-toolbar class="white--text primary mb-2">
        <v-spacer />
        <v-toolbar-title>
          Editar Informações do Gestor
        </v-toolbar-title>
        <v-spacer />
        <template v-slot:extension>
          <v-tabs v-model="activeTab" centered grow>
            <v-tab
              v-for="tab in tabs"
              :key="tab.value"
              :href="tab.href"
              :class="activeTab === tab.value ? 'active-tab' : 'inactive-tab'"
            >
              {{ tab.text }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-card-text>
        <v-tabs-items :key="reRenderTrigger" v-model="activeTab">
          <v-tab-item
            v-for="tab in tabs"
            :key="tab.value"
            :value="tab.value"
            eager
          >
            <component
              :is="tab.componentName"
              :manager="formFields"
              :value="tab.value"
              @change="handleFormChange"
            />
          </v-tab-item>
        </v-tabs-items>
        <v-container>
          <v-row dense justify="end">
            <v-col cols="9" sm="auto">
              <v-btn
                text
                block
                color="error"
                :disabled="loading"
                @click="cancel"
              >
                Descartar
              </v-btn>
            </v-col>
            <v-col cols="9" sm="auto">
              <v-btn
                block
                color="primary"
                type="submit"
                :disabled="!isFormValid || loading"
                :loading="loading"
                @click="update"
              >
                Atualizar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/services/api';
import fillSchema from '@/utils/fill-schema';

import ManagerIntegrationFormTab from './ManagerIntegrationFormTab.vue';
import ManagerReportFormTab from './ManagerReportFormTab.vue';

const formSchema = {
  email: [],
  reportName: null,
};

export default {
  name: 'ManagerEditForm',

  props: {
    manager: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ManagerIntegrationFormTab,
    ManagerReportFormTab,
  },

  watch: {
    manager: {
      immediate: true,
      deep: true,
      handler(updatedManagerData) {
        this.formFields = fillSchema(formSchema, updatedManagerData);
      },
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.manager),

    loading: false,
    dialog: false,
    reRenderTrigger: 0,
    activeTab: 'integration',

    tabs: [
      {
        value: 'integration',
        href: '#integration',
        text: 'Integrações',
        componentName: 'ManagerIntegrationFormTab',
        isFormValid: false,
      },
      {
        value: 'report',
        href: '#report',
        text: 'Relatórios',
        componentName: 'ManagerReportFormTab',
        isFormValid: false,
      },
    ],
  }),

  computed: {
    isFormValid(vm) {
      return vm.tabs.every((tab) => tab.isFormValid);
    },
  },

  methods: {
    handleFormChange(updatedTabValue, formFields, isFormValid) {
      this.formFields = { ...this.formFields, ...formFields };

      const updatedTab = this.tabs.find(
        (tab) => tab.value === updatedTabValue,
      );

      if (updatedTab) {
        updatedTab.isFormValid = isFormValid;
      }
    },

    resetForm() {
      this.formFields = fillSchema(formSchema, this.manager);
      this.activeTab = 'integration';
      this.reRenderTrigger += 1; // Force tabs re-render on form discard
    },

    cancel() {
      this.dialog = false;
      // Delay form reset to avoid visual bugs during dialog close
      setTimeout(this.resetForm, 500);
    },

    async update() {
      this.loading = true;

      try {
        const { data } = await api.managers.updateById(
          this.manager._id,
          this.formFields,
        );
        this.$emit('update', data);
        this.dialog = false;
        this.activeTab = 'integration';
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response?.data.message ?? error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active-tab {
  color: white;
  background: linear-gradient(
    rgba(255, 255, 255, .0),
    rgba(255, 255, 255, .05),
  )
}
.inactive-tab {
  color: white !important;
  opacity: 0.30;
}
</style>
