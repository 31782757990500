<template>
  <v-container>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="managers"
        sort-by="calories"
        class="elevation-1"
        dense
        :footer-props="footerProps"
        :server-items-length="totalFunds"
        @update:page="changePage"
        :items-per-page="50"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Gestores</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Procurar"
              class="mr-5"
              single-line
              hide-details
              @input="isTyping = true"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link class="manager-link" :to="`/managers/${item._id}`">
          {{item.name}}
          </router-link>
        </template>
        <template v-slot:item.action="{ item }">
            <v-btn icon small>
                <!-- <v-icon @click="editItem(item)">mdi-pencil</v-icon> -->
                <v-btn :to="`/managers/${item._id}`" icon x-small>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
            </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<style scoped>
.manager-link {
  text-decoration: none;
  color: #000;
  display: block;
}
</style>

<script>
import _ from 'lodash';
import api from '@/services/api';

export default {
  data() {
    return {
      dialog: false,
      dialogLoading: false,
      managers: [],
      search: '',
      isTyping: false,
      totalFunds: 0,
      query: '',
      defaultItem: {
        name: '',
        reportName: '',
        document: '',
      },
      editedItem: {
        name: '',
        reportName: '',
        document: '',
      },
      footerProps: {
        'disable-items-per-page': true,
      },
      loading: false,
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Documento', value: 'document' },
        // {
        //   text: 'Ações', value: 'action', sortable: false, align: 'center',
        // },
      ],
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    search: _.debounce(function searchWatcher() {
      this.isTyping = false;
    }, 500),
    async isTyping(value) {
      if (!value) {
        if (this.search.length === 0) {
          await this.getData();
          return;
        }
        await this.searchForm(this.search);
      }
    },
  },
  methods: {
    async changePage(page) {
      if (this.query && this.query.length > 0) {
        await this.searchForm(this.query, page);
        return;
      }
      await this.getData(page);
    },
    async getData(page = 1) {
      this.loading = true;
      this.managers = [];
      try {
        const response = await api.managers.list({
          params: {
            page,
          },
        });
        this.totalFunds = response.data.total;
        this.managers = response.data.managers;
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response ? error.response.data.error.message : error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.loading = false;
      }
    },
    editItem(item) {
      this.editedIndex = this.managers.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    async searchForm(query) {
      this.query = query;
      this.managers = [];
      this.loading = true;
      try {
        const response = await api.managers.search({ query });
        this.totalFunds = response.data.total;
        this.managers = response.data.managers;
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response ? error.response.data.error.message : error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        this.dialogLoading = true;
        if (this.editedItem._id) {
          await api.managers.update(this.editedItem._id, this.editedItem);
        } else {
          await api.managers.create(this.editedItem);
        }
        this.$store.dispatch('notification', {
          text: `${this.editedItem.name} atualizado com sucesso!`,
          color: 'success',
          status: true,
        });
        await this.getData();
        this.close();
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response ? error.response.data.error.message : error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.close();
        this.editedItem = this.defaultItem;
      }
    },
    close() {
      this.dialog = false;
      this.dialogLoading = false;
    },
  },
};
</script>
