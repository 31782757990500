<template>
  <v-container>
    <v-card :loading="loading">
      <v-card-title class="d-flex primary white--text justify-space-between">
        <div class="d-flex flex-column">
          <span class="text-h5">{{ manager.name }}</span>
          <span class="text-h6 font-weight-light">CNPJ: {{ manager.document }}</span>
        </div>
        <manager-edit-form :manager="manager" />
      </v-card-title>
      <v-card-text >
        <v-simple-table class="no-hover my-2">
          <tbody>
            <tr>
              <td>
                AUM Estimado
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">mdi-information</v-icon>
                  </template>
                  <span>Soma do PL dos fundos operacionais sob gestão</span>
                </v-tooltip>
              </td>
              <td>{{ formatMoney(manager.equity) }}</td>
              <td class="text-right">Fundos Operacionais</td>
              <td class="text-right">
                {{ formatNumber(operationalFunds.length) }}
                de
                {{ formatNumber(funds.length) }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Busque por nome ou documento"
          class="my-2"
          hide-details
          single-line
          dense
          solo
          flat
        />

        <v-data-table
          must-sort
          dense
          sort-by="cancelDate"
          :search="search"
          :custom-filter="customFilter"
          :headers="headers"
          :items="funds"
          :loading="loading"
          :items-per-page="25"
          :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
        >
          <template v-slot:item.name="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <router-link class="no-link-decoration" :to="`/funds/${item._id}`">
                  <div v-on="on" class="no-wrap">{{ item.shortName }}</div>
                </router-link>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.initialDate="{ item }">
            {{ formatDate(item.initialDate) }}
          </template>
          <template v-slot:item.cancelDate="{ item }">
            {{ formatDate(item.cancelDate) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { formatMoney, formatNumber, formatDate } from '@/utils/format-utils';
import api from '@/services/api';
import ManagerEditForm from './ManagerEditForm.vue';

export default {
  name: 'ManagerDetails',

  components: {
    ManagerEditForm,
  },

  data() {
    return {
      loading: false,

      manager: {},
      funds: [],
      search: '',

      headers: [
        {
          text: 'Nome',
          value: 'name',
        },
        {
          text: 'Documento',
          value: 'document',
          width: '190px',
        },
        {
          text: 'Início da Gestão',
          value: 'initialDate',
          class: 'text-no-wrap',
          width: '100px',
        },
        {
          text: 'Fim da Gestão',
          value: 'cancelDate',
          class: 'text-no-wrap',
          width: '100px',
        },
      ],
    };
  },

  async mounted() {
    await this.getData();
  },

  computed: {
    operationalFunds: (vm) => vm.funds.filter((fund) => !fund.cancelDate),
  },

  methods: {
    formatMoney,
    formatNumber,
    formatDate,

    customFilter(value, search, item) {
      const searchQuery = search.trim().toLowerCase();
      return (
        item.name.toLowerCase().includes(searchQuery)
        || item.shortName.toLowerCase().includes(searchQuery)
        || item.document.includes(searchQuery)
        || item.serial.includes(searchQuery)
      );
    },

    async getData() {
      this.loading = true;

      try {
        const { data } = await api.managers.byId(this.$route.params.id);

        this.manager = data;
        this.funds = data.funds;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-text-field--solo-flat .v-input__slot {
    background-color: #eee !important;
  }
}
.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 600px;
}
.no-hover tbody tr:hover {
  background-color: inherit !important;
}
.no-link-decoration {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
