var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"d-flex primary white--text justify-space-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.manager.name))]),_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v("CNPJ: "+_vm._s(_vm.manager.document))])]),_c('manager-edit-form',{attrs:{"manager":_vm.manager}})],1),_c('v-card-text',[_c('v-simple-table',{staticClass:"no-hover my-2"},[_c('tbody',[_c('tr',[_c('td',[_vm._v(" AUM Estimado "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v("mdi-information")])]}}])},[_c('span',[_vm._v("Soma do PL dos fundos operacionais sob gestão")])])],1),_c('td',[_vm._v(_vm._s(_vm.formatMoney(_vm.manager.equity)))]),_c('td',{staticClass:"text-right"},[_vm._v("Fundos Operacionais")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.operationalFunds.length))+" de "+_vm._s(_vm.formatNumber(_vm.funds.length))+" ")])])])]),_c('v-text-field',{staticClass:"my-2",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Busque por nome ou documento","hide-details":"","single-line":"","dense":"","solo":"","flat":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"must-sort":"","dense":"","sort-by":"cancelDate","search":_vm.search,"custom-filter":_vm.customFilter,"headers":_vm.headers,"items":_vm.funds,"loading":_vm.loading,"items-per-page":25,"footer-props":{ 'items-per-page-options': [25, 50, 100] }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{staticClass:"no-link-decoration",attrs:{"to":("/funds/" + (item._id))}},[_c('div',_vm._g({staticClass:"no-wrap"},on),[_vm._v(_vm._s(item.shortName))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.initialDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.initialDate))+" ")]}},{key:"item.cancelDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.cancelDate))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }