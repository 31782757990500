<template>
  <v-form v-model="isFormValid">
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            label="Nome"
            v-model.trim="formFields.reportName"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import fillSchema from '@/utils/fill-schema';

const formSchema = {
  reportName: null,
};

export default {
  name: 'ManagerReportFormTab',

  props: {
    manager: {
      type: Object,
      default: () => ({}),
    },

    value: {
      type: String,
      default: '',
    },
  },

  watch: {
    formFields: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.$emit('change', this.value, this.formFields, this.isFormValid);
        });
      },
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.manager),
    isFormValid: false,
  }),
};
</script>
