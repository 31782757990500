<template>
  <v-container>
    <v-data-table
      dense
      hide-default-footer
      :headers="headers"
      :items="emailObjects"
    >
      <template v-slot:item.email="{ item, index }">
        <v-form
          v-if="editingIndex === index"
          v-model="isEmailValid"
          @submit.prevent="patchEmail"
        >
          <v-text-field
            dense
            autofocus
            hide-details
            placeholder="Email"
            class="tabular-input"
            :rules="[vRules.requiredField, vRules.validEmail, vRules.uniqueEmail]"
            v-model="emailInput"
          />
        </v-form>
        <span v-else>
          {{ item.email }}
        </span>
      </template>

      <template v-slot:item.actions="{ item, index }">
        <div v-if="editingIndex === index">
          <v-btn
            small
            icon
            type="submit"
            color="success"
            :disabled="!isEmailValid"
            @click="patchEmail"
          >
            <v-icon small>mdi-check-bold</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            color="error"
            @click="reset"
          >
            <v-icon small>mdi-close-thick</v-icon>
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            small
            icon
            color="primary"
            :disabled="isCreating || isEditing"
            @click="() => editEmail(item, index)"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            small
            icon
            color="error"
            :disabled="isCreating || isEditing"
            @click="() => deleteEmail(index)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:body.append v-if="isCreating">
        <tr>
          <td>
            <v-form v-model="isEmailValid" @submit.prevent="createEmail">
              <v-text-field
                dense
                autofocus
                hide-details
                placeholder="Email"
                class="tabular-input"
                :rules="[vRules.requiredField, vRules.validEmail, vRules.uniqueEmail]"
                v-model="emailInput"
              />
            </v-form>
          </td>
          <td class="d-flex justify-end">
            <v-btn
              small
              icon
              type="submit"
              color="success"
              :disabled="!isEmailValid"
              @click="createEmail"
            >
              <v-icon small>mdi-check-bold</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              color="error"
              @click="reset"
            >
              <v-icon small>mdi-close-thick</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-btn
      tile
      small
      width="100%"
      color="primary"
      :solo="isEditing || isCreating"
      :outlined="!isEditing && !isCreating"
      :disabled="isEditing || isCreating"
      @click="addRow"
    >
      <v-icon left>mdi-table-row-plus-after</v-icon>
      Inserir
    </v-btn>
  </v-container>
</template>

<script>
import { requiredField, validEmail } from '@/utils/validators';

import fillSchema from '@/utils/fill-schema';

const formSchema = {
  email: [],
};

export default {
  name: 'ManagerIntegrationFormTab',

  props: {
    manager: {
      type: Object,
      default: () => ({}),
    },

    value: {
      type: String,
      default: '',
    },
  },

  watch: {
    formFields: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.$emit('change', this.value, this.formFields, this.isFormValid);
        });
      },
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.manager),

    emailInput: '',
    isEmailValid: false,
    editingIndex: null,

    vRules: {
      requiredField,
      validEmail,
      uniqueEmail: (v) => !vm.formFields.email.includes(v),
    },

    headers: [
      {
        text: 'Email',
        value: 'email',
        sortable: false,
        align: 'center',
      },
      {
        text: 'Ações',
        value: 'actions',
        sortable: false,
        align: 'end',
        width: '100px',
      },
    ],
  }),

  computed: {
    emailObjects: (vm) => vm.formFields.email.map((email) => ({ email })),

    isEditing: (vm) => vm.editingIndex !== null && !vm.isCreating,
    isCreating: (vm) => vm.editingIndex === vm.formFields.email.length,
    isFormValid: (vm) => (!vm.isEditing && !vm.isCreating) || vm.isEmailValid,
  },

  methods: {
    addRow() {
      this.editingIndex = this.formFields.email.length;
    },

    createEmail() {
      if (!this.isEmailValid) return;

      this.formFields.email.push(this.emailInput);
      this.reset();
    },

    editEmail(item, index) {
      this.emailInput = item.email;
      this.editingIndex = index;
    },

    patchEmail() {
      if (!this.isEmailValid) return;

      this.$set(this.formFields.email, this.editingIndex, this.emailInput);
      this.reset();
    },

    deleteEmail(index) {
      this.formFields.email.splice(index, 1);
    },

    reset() {
      this.emailInput = '';
      this.editingIndex = null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .tabular-input input {
    text-align: center;
    font-size: .875rem;
  }
}
</style>
